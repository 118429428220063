@import url('https://themes.googleusercontent.com/fonts/css?kit=KRlc5aipaMGxMZIuODnyG0tKhbDmIN-HJYiY1CsPFkk');

.privacy .lst-kix_vymryl49aqa6-2 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_vymryl49aqa6-1 > li:before {
  content: '\0025cb  ';
}
.privacy ul.lst-kix_vscxbwqiowtg-8 {
  list-style-type: none;
}
.privacy ul.lst-kix_vscxbwqiowtg-7 {
  list-style-type: none;
}
.privacy .lst-kix_vymryl49aqa6-0 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_o19uucjqpkqp-3 > li:before {
  content: '-  ';
}
.privacy .lst-kix_o19uucjqpkqp-4 > li:before {
  content: '-  ';
}
.privacy .lst-kix_o19uucjqpkqp-5 > li:before {
  content: '-  ';
}
.privacy .lst-kix_o19uucjqpkqp-6 > li:before {
  content: '-  ';
}
.privacy ol.lst-kix_i67oi52whxtu-1.start {
  counter-reset: lst-ctn-kix_i67oi52whxtu-1 0;
}
.privacy .lst-kix_i67oi52whxtu-3 > li {
  counter-increment: lst-ctn-kix_i67oi52whxtu-3;
}
.privacy .lst-kix_vymryl49aqa6-8 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_o19uucjqpkqp-2 > li:before {
  content: '-  ';
}
.privacy .lst-kix_vymryl49aqa6-6 > li:before {
  content: '\0025cf  ';
}
.privacy ul.lst-kix_vscxbwqiowtg-2 {
  list-style-type: none;
}
.privacy .lst-kix_o19uucjqpkqp-0 > li:before {
  content: '-  ';
}
.privacy .lst-kix_o19uucjqpkqp-1 > li:before {
  content: '-  ';
}
.privacy ul.lst-kix_vscxbwqiowtg-1 {
  list-style-type: none;
}
.privacy .lst-kix_i67oi52whxtu-1 > li {
  counter-increment: lst-ctn-kix_i67oi52whxtu-1;
}
.privacy .lst-kix_vymryl49aqa6-5 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_vymryl49aqa6-7 > li:before {
  content: '\0025cb  ';
}
.privacy ul.lst-kix_vscxbwqiowtg-0 {
  list-style-type: none;
}
.privacy ul.lst-kix_vscxbwqiowtg-6 {
  list-style-type: none;
}
.privacy ul.lst-kix_vscxbwqiowtg-5 {
  list-style-type: none;
}
.privacy ul.lst-kix_vscxbwqiowtg-4 {
  list-style-type: none;
}
.privacy ul.lst-kix_vscxbwqiowtg-3 {
  list-style-type: none;
}
.privacy ul.lst-kix_nnh3kjfeha5-6 {
  list-style-type: none;
}
.privacy ul.lst-kix_nnh3kjfeha5-7 {
  list-style-type: none;
}
.privacy .lst-kix_vymryl49aqa6-3 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_nnh3kjfeha5-2 > li:before {
  content: '\0025a0  ';
}
.privacy ul.lst-kix_nnh3kjfeha5-8 {
  list-style-type: none;
}
.privacy .lst-kix_vymryl49aqa6-4 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_nnh3kjfeha5-1 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_h4w1bdy6stb9-6 > li:before {
  content: '-  ';
}
.privacy .lst-kix_h4w1bdy6stb9-5 > li:before {
  content: '-  ';
}
.privacy .lst-kix_h4w1bdy6stb9-7 > li:before {
  content: '-  ';
}
.privacy .lst-kix_h4w1bdy6stb9-2 > li:before {
  content: '-  ';
}
.privacy .lst-kix_nnh3kjfeha5-3 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_g8ch3io404f2-7 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_h4w1bdy6stb9-1 > li:before {
  content: '-  ';
}
.privacy .lst-kix_nnh3kjfeha5-4 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_h4w1bdy6stb9-0 > li:before {
  content: '-  ';
}
.privacy .lst-kix_h4w1bdy6stb9-8 > li:before {
  content: '-  ';
}
.privacy .lst-kix_nnh3kjfeha5-5 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_g8ch3io404f2-8 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_nnh3kjfeha5-7 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_g8ch3io404f2-2 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_g8ch3io404f2-3 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_nnh3kjfeha5-6 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_nnh3kjfeha5-8 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_g8ch3io404f2-6 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_h4w1bdy6stb9-3 > li:before {
  content: '-  ';
}
.privacy .lst-kix_h4w1bdy6stb9-4 > li:before {
  content: '-  ';
}
.privacy .lst-kix_g8ch3io404f2-4 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_g8ch3io404f2-5 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_i67oi52whxtu-8 > li:before {
  content: '' counter(lst-ctn-kix_i67oi52whxtu-8, lower-roman) '. ';
}
.privacy .lst-kix_i67oi52whxtu-7 > li:before {
  content: '' counter(lst-ctn-kix_i67oi52whxtu-7, lower-latin) '. ';
}
.privacy .lst-kix_i67oi52whxtu-6 > li:before {
  content: '' counter(lst-ctn-kix_i67oi52whxtu-6, decimal) '. ';
}
.privacy .lst-kix_i67oi52whxtu-4 > li:before {
  content: '' counter(lst-ctn-kix_i67oi52whxtu-4, lower-latin) '. ';
}
.privacy .lst-kix_i67oi52whxtu-3 > li:before {
  content: '' counter(lst-ctn-kix_i67oi52whxtu-3, decimal) '. ';
}
.privacy .lst-kix_i67oi52whxtu-5 > li:before {
  content: '' counter(lst-ctn-kix_i67oi52whxtu-5, lower-roman) '. ';
}
.privacy .lst-kix_i67oi52whxtu-1 > li:before {
  content: '' counter(lst-ctn-kix_i67oi52whxtu-1, lower-latin) '. ';
}
.privacy .lst-kix_i67oi52whxtu-5 > li {
  counter-increment: lst-ctn-kix_i67oi52whxtu-5;
}
.privacy .lst-kix_i67oi52whxtu-2 > li:before {
  content: '' counter(lst-ctn-kix_i67oi52whxtu-2, lower-roman) '. ';
}
.privacy .lst-kix_i67oi52whxtu-8 > li {
  counter-increment: lst-ctn-kix_i67oi52whxtu-8;
}
.privacy ol.lst-kix_i67oi52whxtu-1 {
  list-style-type: none;
}
.privacy ol.lst-kix_i67oi52whxtu-0 {
  list-style-type: none;
}
.privacy ol.lst-kix_i67oi52whxtu-3 {
  list-style-type: none;
}
.privacy ol.lst-kix_i67oi52whxtu-2 {
  list-style-type: none;
}
.privacy ol.lst-kix_i67oi52whxtu-5 {
  list-style-type: none;
}
.privacy ol.lst-kix_i67oi52whxtu-4 {
  list-style-type: none;
}
.privacy ol.lst-kix_i67oi52whxtu-7 {
  list-style-type: none;
}
.privacy ol.lst-kix_i67oi52whxtu-6 {
  list-style-type: none;
}
.privacy .lst-kix_i67oi52whxtu-0 > li:before {
  content: '' counter(lst-ctn-kix_i67oi52whxtu-0, upper-latin) '. ';
}
.privacy ol.lst-kix_i67oi52whxtu-8 {
  list-style-type: none;
}
.privacy .lst-kix_i67oi52whxtu-7 > li {
  counter-increment: lst-ctn-kix_i67oi52whxtu-7;
}
.privacy ul.lst-kix_d960fvn1zmr0-8 {
  list-style-type: none;
}
.privacy ul.lst-kix_d960fvn1zmr0-6 {
  list-style-type: none;
}
.privacy ul.lst-kix_d960fvn1zmr0-7 {
  list-style-type: none;
}
.privacy ul.lst-kix_d960fvn1zmr0-4 {
  list-style-type: none;
}
.privacy ul.lst-kix_d960fvn1zmr0-5 {
  list-style-type: none;
}
.privacy ul.lst-kix_d960fvn1zmr0-2 {
  list-style-type: none;
}
.privacy ul.lst-kix_d960fvn1zmr0-3 {
  list-style-type: none;
}
.privacy ul.lst-kix_d960fvn1zmr0-0 {
  list-style-type: none;
}
.privacy ul.lst-kix_d960fvn1zmr0-1 {
  list-style-type: none;
}
.privacy ul.lst-kix_o19uucjqpkqp-2 {
  list-style-type: none;
}
.privacy ul.lst-kix_o19uucjqpkqp-3 {
  list-style-type: none;
}
.privacy ul.lst-kix_o19uucjqpkqp-0 {
  list-style-type: none;
}
.privacy ul.lst-kix_o19uucjqpkqp-1 {
  list-style-type: none;
}
.privacy .lst-kix_llroftrfjxym-0 > li:before {
  content: '\0025cf  ';
}
.privacy ol.lst-kix_i67oi52whxtu-2.start {
  counter-reset: lst-ctn-kix_i67oi52whxtu-2 0;
}
.privacy .lst-kix_joixe9qczi0p-0 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_i67oi52whxtu-6 > li {
  counter-increment: lst-ctn-kix_i67oi52whxtu-6;
}
.privacy .lst-kix_llroftrfjxym-2 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_llroftrfjxym-4 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_g8ch3io404f2-0 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_2q0ly3ivnajt-8 > li:before {
  content: '-  ';
}
.privacy .lst-kix_vscxbwqiowtg-7 > li:before {
  content: '-  ';
}
.privacy .lst-kix_5cp4ivmq2pus-7 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_2q0ly3ivnajt-6 > li:before {
  content: '-  ';
}
.privacy ul.lst-kix_o19uucjqpkqp-8 {
  list-style-type: none;
}
.privacy ul.lst-kix_o19uucjqpkqp-6 {
  list-style-type: none;
}
.privacy ul.lst-kix_o19uucjqpkqp-7 {
  list-style-type: none;
}
.privacy ul.lst-kix_o19uucjqpkqp-4 {
  list-style-type: none;
}
.privacy ul.lst-kix_o19uucjqpkqp-5 {
  list-style-type: none;
}
.privacy .lst-kix_5cp4ivmq2pus-1 > li:before {
  content: '\0025cb  ';
}
.privacy ul.lst-kix_5cp4ivmq2pus-3 {
  list-style-type: none;
}
.privacy .lst-kix_2q0ly3ivnajt-0 > li:before {
  content: '-  ';
}
.privacy ul.lst-kix_5cp4ivmq2pus-2 {
  list-style-type: none;
}
.privacy ul.lst-kix_nnh3kjfeha5-0 {
  list-style-type: none;
}
.privacy ul.lst-kix_5cp4ivmq2pus-5 {
  list-style-type: none;
}
.privacy ul.lst-kix_nnh3kjfeha5-1 {
  list-style-type: none;
}
.privacy ul.lst-kix_5cp4ivmq2pus-4 {
  list-style-type: none;
}
.privacy ul.lst-kix_nnh3kjfeha5-2 {
  list-style-type: none;
}
.privacy .lst-kix_5cp4ivmq2pus-3 > li:before {
  content: '\0025cf  ';
}
.privacy ul.lst-kix_5cp4ivmq2pus-7 {
  list-style-type: none;
}
.privacy .lst-kix_2q0ly3ivnajt-2 > li:before {
  content: '-  ';
}
.privacy ul.lst-kix_nnh3kjfeha5-3 {
  list-style-type: none;
}
.privacy ul.lst-kix_5cp4ivmq2pus-6 {
  list-style-type: none;
}
.privacy .lst-kix_vscxbwqiowtg-1 > li:before {
  content: '-  ';
}
.privacy ul.lst-kix_nnh3kjfeha5-4 {
  list-style-type: none;
}
.privacy ul.lst-kix_nnh3kjfeha5-5 {
  list-style-type: none;
}
.privacy ul.lst-kix_5cp4ivmq2pus-8 {
  list-style-type: none;
}
.privacy .lst-kix_5cp4ivmq2pus-5 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_2q0ly3ivnajt-4 > li:before {
  content: '-  ';
}
.privacy .lst-kix_joixe9qczi0p-6 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_xd8c0k37uf41-8 > li:before {
  content: '-  ';
}
.privacy .lst-kix_joixe9qczi0p-4 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_vscxbwqiowtg-5 > li:before {
  content: '-  ';
}
.privacy .lst-kix_joixe9qczi0p-2 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_xd8c0k37uf41-4 > li:before {
  content: '-  ';
}
.privacy .lst-kix_vscxbwqiowtg-3 > li:before {
  content: '-  ';
}
.privacy .lst-kix_i67oi52whxtu-0 > li {
  counter-increment: lst-ctn-kix_i67oi52whxtu-0;
}
.privacy .lst-kix_xd8c0k37uf41-6 > li:before {
  content: '-  ';
}
.privacy ul.lst-kix_ftk2qzsas2ri-6 {
  list-style-type: none;
}
.privacy ul.lst-kix_ftk2qzsas2ri-5 {
  list-style-type: none;
}
.privacy .lst-kix_xd8c0k37uf41-0 > li:before {
  content: '-  ';
}
.privacy ul.lst-kix_ftk2qzsas2ri-4 {
  list-style-type: none;
}
.privacy ul.lst-kix_ftk2qzsas2ri-3 {
  list-style-type: none;
}
.privacy ul.lst-kix_ftk2qzsas2ri-2 {
  list-style-type: none;
}
.privacy ul.lst-kix_ftk2qzsas2ri-1 {
  list-style-type: none;
}
.privacy .lst-kix_o19uucjqpkqp-7 > li:before {
  content: '-  ';
}
.privacy ul.lst-kix_ftk2qzsas2ri-0 {
  list-style-type: none;
}
.privacy .lst-kix_1x3u74tdrocq-4 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_1x3u74tdrocq-8 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_xd8c0k37uf41-2 > li:before {
  content: '-  ';
}
.privacy ul.lst-kix_ftk2qzsas2ri-8 {
  list-style-type: none;
}
.privacy ul.lst-kix_ftk2qzsas2ri-7 {
  list-style-type: none;
}
.privacy .lst-kix_1x3u74tdrocq-6 > li:before {
  content: '\0025cf  ';
}
.privacy ol.lst-kix_i67oi52whxtu-0.start {
  counter-reset: lst-ctn-kix_i67oi52whxtu-0 0;
}
.privacy .lst-kix_joixe9qczi0p-8 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_1x3u74tdrocq-0 > li:before {
  content: '\0025cf  ';
}
.privacy ul.lst-kix_5cp4ivmq2pus-1 {
  list-style-type: none;
}
.privacy .lst-kix_1x3u74tdrocq-2 > li:before {
  content: '\0025a0  ';
}
.privacy ul.lst-kix_5cp4ivmq2pus-0 {
  list-style-type: none;
}
.privacy .lst-kix_i67oi52whxtu-2 > li {
  counter-increment: lst-ctn-kix_i67oi52whxtu-2;
}
.privacy .lst-kix_mym8wp3wm8b9-1 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_mym8wp3wm8b9-0 > li:before {
  content: '\0025cf  ';
}
.privacy ul.lst-kix_1x3u74tdrocq-1 {
  list-style-type: none;
}
.privacy ul.lst-kix_1x3u74tdrocq-0 {
  list-style-type: none;
}
.privacy .lst-kix_mym8wp3wm8b9-5 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_mym8wp3wm8b9-6 > li:before {
  content: '\0025cf  ';
}
.privacy ul.lst-kix_xd8c0k37uf41-0 {
  list-style-type: none;
}
.privacy ul.lst-kix_1x3u74tdrocq-3 {
  list-style-type: none;
}
.privacy ul.lst-kix_1x3u74tdrocq-2 {
  list-style-type: none;
}
.privacy ul.lst-kix_xd8c0k37uf41-2 {
  list-style-type: none;
}
.privacy ul.lst-kix_1x3u74tdrocq-5 {
  list-style-type: none;
}
.privacy ul.lst-kix_xd8c0k37uf41-1 {
  list-style-type: none;
}
.privacy .lst-kix_d960fvn1zmr0-0 > li:before {
  content: '\0025cf  ';
}
.privacy ul.lst-kix_1x3u74tdrocq-4 {
  list-style-type: none;
}
.privacy ul.lst-kix_xd8c0k37uf41-4 {
  list-style-type: none;
}
.privacy ul.lst-kix_1x3u74tdrocq-7 {
  list-style-type: none;
}
.privacy ul.lst-kix_33tz5umxnvhf-1 {
  list-style-type: none;
}
.privacy ul.lst-kix_xd8c0k37uf41-3 {
  list-style-type: none;
}
.privacy ul.lst-kix_1x3u74tdrocq-6 {
  list-style-type: none;
}
.privacy ul.lst-kix_33tz5umxnvhf-0 {
  list-style-type: none;
}
.privacy ul.lst-kix_xd8c0k37uf41-6 {
  list-style-type: none;
}
.privacy ul.lst-kix_33tz5umxnvhf-3 {
  list-style-type: none;
}
.privacy ul.lst-kix_xd8c0k37uf41-5 {
  list-style-type: none;
}
.privacy .lst-kix_d960fvn1zmr0-2 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_d960fvn1zmr0-3 > li:before {
  content: '\0025cf  ';
}
.privacy ul.lst-kix_1x3u74tdrocq-8 {
  list-style-type: none;
}
.privacy .lst-kix_mym8wp3wm8b9-2 > li:before {
  content: '\0025a0  ';
}
.privacy ul.lst-kix_33tz5umxnvhf-2 {
  list-style-type: none;
}
.privacy ul.lst-kix_xd8c0k37uf41-8 {
  list-style-type: none;
}
.privacy ul.lst-kix_33tz5umxnvhf-5 {
  list-style-type: none;
}
.privacy ul.lst-kix_xd8c0k37uf41-7 {
  list-style-type: none;
}
.privacy ul.lst-kix_33tz5umxnvhf-4 {
  list-style-type: none;
}
.privacy ul.lst-kix_33tz5umxnvhf-7 {
  list-style-type: none;
}
.privacy .lst-kix_d960fvn1zmr0-1 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_mym8wp3wm8b9-3 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_mym8wp3wm8b9-4 > li:before {
  content: '\0025cb  ';
}
.privacy ul.lst-kix_33tz5umxnvhf-6 {
  list-style-type: none;
}
.privacy ul.lst-kix_33tz5umxnvhf-8 {
  list-style-type: none;
}
.privacy .lst-kix_d960fvn1zmr0-6 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_d960fvn1zmr0-7 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_d960fvn1zmr0-4 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_d960fvn1zmr0-8 > li:before {
  content: '\0025a0  ';
}
.privacy ul.lst-kix_mym8wp3wm8b9-0 {
  list-style-type: none;
}
.privacy ul.lst-kix_mym8wp3wm8b9-1 {
  list-style-type: none;
}
.privacy ul.lst-kix_mym8wp3wm8b9-2 {
  list-style-type: none;
}
.privacy ul.lst-kix_mym8wp3wm8b9-3 {
  list-style-type: none;
}
.privacy ul.lst-kix_mym8wp3wm8b9-4 {
  list-style-type: none;
}
.privacy ul.lst-kix_mym8wp3wm8b9-5 {
  list-style-type: none;
}
.privacy ul.lst-kix_mym8wp3wm8b9-6 {
  list-style-type: none;
}
.privacy ul.lst-kix_mym8wp3wm8b9-7 {
  list-style-type: none;
}
.privacy .lst-kix_d960fvn1zmr0-5 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_mym8wp3wm8b9-7 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_mym8wp3wm8b9-8 > li:before {
  content: '\0025a0  ';
}
.privacy ul.lst-kix_mym8wp3wm8b9-8 {
  list-style-type: none;
}
.privacy .lst-kix_ftk2qzsas2ri-6 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_ftk2qzsas2ri-5 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_ftk2qzsas2ri-7 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_ftk2qzsas2ri-4 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_ftk2qzsas2ri-8 > li:before {
  content: '\0025a0  ';
}
.privacy ul.lst-kix_g8ch3io404f2-8 {
  list-style-type: none;
}
.privacy ul.lst-kix_g8ch3io404f2-7 {
  list-style-type: none;
}
.privacy ul.lst-kix_g8ch3io404f2-6 {
  list-style-type: none;
}
.privacy ul.lst-kix_g8ch3io404f2-5 {
  list-style-type: none;
}
.privacy ul.lst-kix_g8ch3io404f2-4 {
  list-style-type: none;
}
.privacy ul.lst-kix_g8ch3io404f2-3 {
  list-style-type: none;
}
.privacy ul.lst-kix_g8ch3io404f2-2 {
  list-style-type: none;
}
.privacy ul.lst-kix_g8ch3io404f2-1 {
  list-style-type: none;
}
.privacy ol.lst-kix_i67oi52whxtu-3.start {
  counter-reset: lst-ctn-kix_i67oi52whxtu-3 0;
}
.privacy ul.lst-kix_g8ch3io404f2-0 {
  list-style-type: none;
}
.privacy .lst-kix_33tz5umxnvhf-8 > li:before {
  content: '\0025a0  ';
}
.privacy ul.lst-kix_llroftrfjxym-8 {
  list-style-type: none;
}
.privacy ul.lst-kix_h4w1bdy6stb9-8 {
  list-style-type: none;
}
.privacy .lst-kix_llroftrfjxym-6 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_llroftrfjxym-8 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_ftk2qzsas2ri-0 > li:before {
  content: '\0025cf  ';
}
.privacy ul.lst-kix_h4w1bdy6stb9-6 {
  list-style-type: none;
}
.privacy ul.lst-kix_h4w1bdy6stb9-7 {
  list-style-type: none;
}
.privacy ul.lst-kix_h4w1bdy6stb9-4 {
  list-style-type: none;
}
.privacy ul.lst-kix_h4w1bdy6stb9-5 {
  list-style-type: none;
}
.privacy .lst-kix_llroftrfjxym-5 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_ftk2qzsas2ri-2 > li:before {
  content: '\0025a0  ';
}
.privacy ul.lst-kix_llroftrfjxym-1 {
  list-style-type: none;
}
.privacy ul.lst-kix_llroftrfjxym-0 {
  list-style-type: none;
}
.privacy .lst-kix_ftk2qzsas2ri-1 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_ftk2qzsas2ri-3 > li:before {
  content: '\0025cf  ';
}
.privacy ul.lst-kix_llroftrfjxym-3 {
  list-style-type: none;
}
.privacy ul.lst-kix_llroftrfjxym-2 {
  list-style-type: none;
}
.privacy ul.lst-kix_llroftrfjxym-5 {
  list-style-type: none;
}
.privacy ul.lst-kix_llroftrfjxym-4 {
  list-style-type: none;
}
.privacy ul.lst-kix_llroftrfjxym-7 {
  list-style-type: none;
}
.privacy ul.lst-kix_llroftrfjxym-6 {
  list-style-type: none;
}
.privacy .lst-kix_llroftrfjxym-7 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_33tz5umxnvhf-1 > li:before {
  content: '\0025cb  ';
}
.privacy ol.lst-kix_i67oi52whxtu-4.start {
  counter-reset: lst-ctn-kix_i67oi52whxtu-4 0;
}
.privacy .lst-kix_33tz5umxnvhf-2 > li:before {
  content: '\0025a0  ';
}
.privacy ul.lst-kix_h4w1bdy6stb9-2 {
  list-style-type: none;
}
.privacy ul.lst-kix_h4w1bdy6stb9-3 {
  list-style-type: none;
}
.privacy ul.lst-kix_h4w1bdy6stb9-0 {
  list-style-type: none;
}
.privacy ul.lst-kix_h4w1bdy6stb9-1 {
  list-style-type: none;
}
.privacy .lst-kix_33tz5umxnvhf-0 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_33tz5umxnvhf-7 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_33tz5umxnvhf-6 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_33tz5umxnvhf-3 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_33tz5umxnvhf-5 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_33tz5umxnvhf-4 > li:before {
  content: '\0025cb  ';
}
.privacy ol.lst-kix_i67oi52whxtu-8.start {
  counter-reset: lst-ctn-kix_i67oi52whxtu-8 0;
}
.privacy .lst-kix_llroftrfjxym-1 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_g8ch3io404f2-1 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_llroftrfjxym-3 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_vscxbwqiowtg-8 > li:before {
  content: '-  ';
}
.privacy .lst-kix_5cp4ivmq2pus-8 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_2q0ly3ivnajt-7 > li:before {
  content: '-  ';
}
.privacy ol.lst-kix_i67oi52whxtu-5.start {
  counter-reset: lst-ctn-kix_i67oi52whxtu-5 0;
}
.privacy .lst-kix_vscxbwqiowtg-0 > li:before {
  content: '-  ';
}
.privacy .lst-kix_nnh3kjfeha5-0 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_5cp4ivmq2pus-0 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_5cp4ivmq2pus-2 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_2q0ly3ivnajt-1 > li:before {
  content: '-  ';
}
.privacy .lst-kix_5cp4ivmq2pus-4 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_5cp4ivmq2pus-6 > li:before {
  content: '\0025cf  ';
}
.privacy .lst-kix_2q0ly3ivnajt-3 > li:before {
  content: '-  ';
}
.privacy .lst-kix_2q0ly3ivnajt-5 > li:before {
  content: '-  ';
}
.privacy .lst-kix_vscxbwqiowtg-6 > li:before {
  content: '-  ';
}
.privacy .lst-kix_i67oi52whxtu-4 > li {
  counter-increment: lst-ctn-kix_i67oi52whxtu-4;
}
.privacy ol.lst-kix_i67oi52whxtu-6.start {
  counter-reset: lst-ctn-kix_i67oi52whxtu-6 0;
}
.privacy .lst-kix_xd8c0k37uf41-7 > li:before {
  content: '-  ';
}
.privacy .lst-kix_joixe9qczi0p-5 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_vscxbwqiowtg-4 > li:before {
  content: '-  ';
}
.privacy .lst-kix_joixe9qczi0p-1 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_joixe9qczi0p-3 > li:before {
  content: '\0025cf  ';
}
.privacy ul.lst-kix_2q0ly3ivnajt-1 {
  list-style-type: none;
}
.privacy .lst-kix_xd8c0k37uf41-5 > li:before {
  content: '-  ';
}
.privacy .lst-kix_vscxbwqiowtg-2 > li:before {
  content: '-  ';
}
.privacy ul.lst-kix_2q0ly3ivnajt-0 {
  list-style-type: none;
}
.privacy ul.lst-kix_joixe9qczi0p-0 {
  list-style-type: none;
}
.privacy ul.lst-kix_vymryl49aqa6-1 {
  list-style-type: none;
}
.privacy ul.lst-kix_2q0ly3ivnajt-5 {
  list-style-type: none;
}
.privacy ul.lst-kix_joixe9qczi0p-1 {
  list-style-type: none;
}
.privacy .lst-kix_o19uucjqpkqp-8 > li:before {
  content: '-  ';
}
.privacy ul.lst-kix_vymryl49aqa6-0 {
  list-style-type: none;
}
.privacy ul.lst-kix_2q0ly3ivnajt-4 {
  list-style-type: none;
}
.privacy ul.lst-kix_joixe9qczi0p-2 {
  list-style-type: none;
}
.privacy ul.lst-kix_vymryl49aqa6-3 {
  list-style-type: none;
}
.privacy ul.lst-kix_2q0ly3ivnajt-3 {
  list-style-type: none;
}
.privacy ul.lst-kix_joixe9qczi0p-3 {
  list-style-type: none;
}
.privacy ul.lst-kix_vymryl49aqa6-2 {
  list-style-type: none;
}
.privacy .lst-kix_1x3u74tdrocq-5 > li:before {
  content: '\0025a0  ';
}
.privacy .lst-kix_1x3u74tdrocq-7 > li:before {
  content: '\0025cb  ';
}
.privacy ul.lst-kix_2q0ly3ivnajt-2 {
  list-style-type: none;
}
.privacy ul.lst-kix_joixe9qczi0p-4 {
  list-style-type: none;
}
.privacy ul.lst-kix_joixe9qczi0p-5 {
  list-style-type: none;
}
.privacy ul.lst-kix_2q0ly3ivnajt-8 {
  list-style-type: none;
}
.privacy ul.lst-kix_joixe9qczi0p-6 {
  list-style-type: none;
}
.privacy ul.lst-kix_2q0ly3ivnajt-7 {
  list-style-type: none;
}
.privacy ul.lst-kix_joixe9qczi0p-7 {
  list-style-type: none;
}
.privacy ul.lst-kix_2q0ly3ivnajt-6 {
  list-style-type: none;
}
.privacy .lst-kix_xd8c0k37uf41-3 > li:before {
  content: '-  ';
}
.privacy ul.lst-kix_vymryl49aqa6-8 {
  list-style-type: none;
}
.privacy ol.lst-kix_i67oi52whxtu-7.start {
  counter-reset: lst-ctn-kix_i67oi52whxtu-7 0;
}
.privacy ul.lst-kix_vymryl49aqa6-5 {
  list-style-type: none;
}
.privacy .lst-kix_xd8c0k37uf41-1 > li:before {
  content: '-  ';
}
.privacy ul.lst-kix_vymryl49aqa6-4 {
  list-style-type: none;
}
.privacy li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.privacy ul.lst-kix_vymryl49aqa6-7 {
  list-style-type: none;
}
.privacy ul.lst-kix_vymryl49aqa6-6 {
  list-style-type: none;
}
.privacy .lst-kix_joixe9qczi0p-7 > li:before {
  content: '\0025cb  ';
}
.privacy ul.lst-kix_joixe9qczi0p-8 {
  list-style-type: none;
}
.privacy .lst-kix_1x3u74tdrocq-1 > li:before {
  content: '\0025cb  ';
}
.privacy .lst-kix_1x3u74tdrocq-3 > li:before {
  content: '\0025cf  ';
}
.privacy ol {
  margin: 0;
  padding: 0;
}
.privacy table td,
.privacy table th {
  padding: 0;
}
.privacy .c10 {
  border-right-style: solid;
  padding: 5pt 5pt 5pt 5pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 252pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.privacy .c0 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.privacy .c17 {
  -webkit-text-decoration-skip: none;
  color: #000000;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 11pt;
  font-style: italic;
}
.privacy .c7 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: normal;
}
.privacy .c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: normal;
}
.privacy .c8 {
  -webkit-text-decoration-skip: none;
  color: #000000;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 11pt;
  font-style: normal;
}
.privacy .c1 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy .c12 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;
  font-style: normal;
}
.privacy .c19 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16pt;
  font-style: normal;
}
.privacy .c21 {
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}
.privacy .c14 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.privacy .c5 {
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.privacy .c18 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
}
.privacy .c15 {
  background-color: #ffffff;
  max-width: 756pt;
  padding: 36pt 18pt 36pt 18pt;
}
.privacy .c3 {
  font-weight: 400;
}
.privacy .c4 {
  padding: 0;
  margin: 0;
}
.privacy .c6 {
  margin-left: 36pt;
  padding-left: 0pt;
}
.privacy .c13 {
  color: inherit;
  text-decoration: inherit;
}
.privacy .c11 {
  font-weight: 700;
}
.privacy .c20 {
  font-size: 14pt;
}
.privacy .c16 {
  height: 0pt;
}
.privacy .c9 {
  height: 11pt;
}
.privacy .title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy .subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy li {
  color: #000000;
  font-size: 11pt;
}
.privacy p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
}
.privacy h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.privacy .li-bullet-0 {
  color: black;
  text-decoration: none;
  padding: 5px 0 5px 5px;
  margin-left: 19px;
}
.privacy .lst-kix_esscojthnlic-1 {
  padding-left: 30px;
}
.privacy .c13 {
  margin: 10px 0 6px;
}
.privacy .c8 {
  margin: 10px 0 6px 0;
}
