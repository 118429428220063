@import url('https://themes.googleusercontent.com/fonts/css?kit=KRlc5aipaMGxMZIuODnyG0tKhbDmIN-HJYiY1CsPFkk');

.terms li:before {
  content: '\0025cf   ';
}
.terms .lst-kix_vlg7e6ap3rmy-2 > li:before {
  content: '\0025a0   ';
}
.terms .lst-kix_vlg7e6ap3rmy-4 > li:before {
  content: '\0025cb   ';
}
.terms ul.lst-kix_ywxw27oksme7-5 {
  list-style-type: none;
}
.terms ul.lst-kix_ywxw27oksme7-6 {
  list-style-type: none;
}
.terms .lst-kix_vlg7e6ap3rmy-6 > li:before {
  content: '\0025cf   ';
}
.terms ul.lst-kix_ywxw27oksme7-3 {
  list-style-type: none;
}
.terms ul.lst-kix_ywxw27oksme7-4 {
  list-style-type: none;
}
.terms .lst-kix_vlg7e6ap3rmy-5 > li:before {
  content: '\0025a0   ';
}
.terms ul.lst-kix_ywxw27oksme7-1 {
  list-style-type: none;
}
.terms ul.lst-kix_ywxw27oksme7-2 {
  list-style-type: none;
}
.terms ul.lst-kix_ywxw27oksme7-0 {
  list-style-type: none;
}
.terms .lst-kix_3s4ckjwdktv1-8 > li:before {
  content: '\0025a0   ';
}
.terms .lst-kix_ywxw27oksme7-5 > li:before {
  content: '\0025a0   ';
}
.terms ul.lst-kix_ywxw27oksme7-7 {
  list-style-type: none;
}
.terms .lst-kix_3s4ckjwdktv1-7 > li:before {
  content: '\0025cb   ';
}
.terms .lst-kix_ywxw27oksme7-4 > li:before {
  content: '\0025cb   ';
}
.terms ul.lst-kix_ywxw27oksme7-8 {
  list-style-type: none;
}
.terms .lst-kix_vlg7e6ap3rmy-7 > li:before {
  content: '\0025cb   ';
}
.terms ul.lst-kix_3s4ckjwdktv1-0 {
  list-style-type: none;
}
.terms ul.lst-kix_3s4ckjwdktv1-1 {
  list-style-type: none;
}
.terms .lst-kix_338o46enhczd-8 > li:before {
  content: '\0025a0   ';
}
.terms .lst-kix_ywxw27oksme7-1 > li:before {
  content: '\0025cb   ';
}
.terms .lst-kix_ywxw27oksme7-3 > li:before {
  content: '\0025cf   ';
}
.terms .lst-kix_vlg7e6ap3rmy-8 > li:before {
  content: '\0025a0   ';
}
.terms .lst-kix_338o46enhczd-7 > li:before {
  content: '\0025cb   ';
}
.terms .lst-kix_ywxw27oksme7-2 > li:before {
  content: '\0025a0   ';
}
.terms .lst-kix_ywxw27oksme7-0 > li:before {
  content: '\0025a0   ';
}
.terms ul.lst-kix_338o46enhczd-0 {
  list-style-type: none;
}
.terms ul.lst-kix_338o46enhczd-1 {
  list-style-type: none;
}
.terms ul.lst-kix_338o46enhczd-4 {
  list-style-type: none;
}
.terms ul.lst-kix_338o46enhczd-5 {
  list-style-type: none;
}
.terms ul.lst-kix_338o46enhczd-2 {
  list-style-type: none;
}
.terms ul.lst-kix_338o46enhczd-3 {
  list-style-type: none;
}
.terms ul.lst-kix_338o46enhczd-8 {
  list-style-type: none;
}
.terms ul.lst-kix_338o46enhczd-6 {
  list-style-type: none;
}
.terms ul.lst-kix_338o46enhczd-7 {
  list-style-type: none;
}
.terms ul.lst-kix_3s4ckjwdktv1-6 {
  list-style-type: none;
}
.terms ul.lst-kix_vlg7e6ap3rmy-3 {
  list-style-type: none;
}
.terms ul.lst-kix_3s4ckjwdktv1-7 {
  list-style-type: none;
}
.terms ul.lst-kix_vlg7e6ap3rmy-2 {
  list-style-type: none;
}
.terms ul.lst-kix_3s4ckjwdktv1-8 {
  list-style-type: none;
}
.terms ul.lst-kix_vlg7e6ap3rmy-1 {
  list-style-type: none;
}
.terms .lst-kix_3s4ckjwdktv1-0 > li:before {
  content: '\0025a0   ';
}
.terms .lst-kix_3s4ckjwdktv1-2 > li:before {
  content: '\0025a0   ';
}
.terms ul.lst-kix_vlg7e6ap3rmy-0 {
  list-style-type: none;
}
.terms .lst-kix_338o46enhczd-4 > li:before {
  content: '\0025cb   ';
}
.terms .lst-kix_338o46enhczd-6 > li:before {
  content: '\0025cf   ';
}
.terms ul.lst-kix_3s4ckjwdktv1-2 {
  list-style-type: none;
}
.terms ul.lst-kix_vlg7e6ap3rmy-7 {
  list-style-type: none;
}
.terms ul.lst-kix_3s4ckjwdktv1-3 {
  list-style-type: none;
}
.terms ul.lst-kix_vlg7e6ap3rmy-6 {
  list-style-type: none;
}
.terms ul.lst-kix_3s4ckjwdktv1-4 {
  list-style-type: none;
}
.terms ul.lst-kix_vlg7e6ap3rmy-5 {
  list-style-type: none;
}
.terms .lst-kix_3s4ckjwdktv1-3 > li:before {
  content: '\0025cf   ';
}
.terms ul.lst-kix_3s4ckjwdktv1-5 {
  list-style-type: none;
}
.terms ul.lst-kix_vlg7e6ap3rmy-4 {
  list-style-type: none;
}
.terms .lst-kix_338o46enhczd-5 > li:before {
  content: '\0025a0   ';
}
.terms .lst-kix_3s4ckjwdktv1-4 > li:before {
  content: '\0025cb   ';
}
.terms .lst-kix_3s4ckjwdktv1-6 > li:before {
  content: '\0025cf   ';
}
.terms ul.lst-kix_vlg7e6ap3rmy-8 {
  list-style-type: none;
}
.terms .lst-kix_338o46enhczd-2 > li:before {
  content: '\0025a0   ';
}
.terms li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.terms .lst-kix_3s4ckjwdktv1-5 > li:before {
  content: '\0025a0   ';
}
.terms .lst-kix_338o46enhczd-3 > li:before {
  content: '\0025cf   ';
}
.terms .lst-kix_ywxw27oksme7-6 > li:before {
  content: '\0025cf   ';
}
.terms .lst-kix_ywxw27oksme7-7 > li:before {
  content: '\0025cb   ';
}
.terms .lst-kix_338o46enhczd-1 > li:before {
  content: '\0025cb   ';
}
.terms .lst-kix_ywxw27oksme7-8 > li:before {
  content: '\0025a0   ';
}
.terms .lst-kix_vlg7e6ap3rmy-0 > li:before {
  content: '\0025a0   ';
}
.terms .lst-kix_338o46enhczd-0 > li:before {
  content: '\0025a0   ';
}
.terms .lst-kix_vlg7e6ap3rmy-1 > li:before {
  content: '\0025cb   ';
}
.terms .lst-kix_3s4ckjwdktv1-1 > li:before {
  content: '\0025cb   ';
}
.terms ol {
  margin: 0;
  padding: 0;
}
.terms table td,
.terms table th {
  padding: 0;
}
.terms .c8 {
  margin-left: 36pt;
  padding-top: 11pt;
  padding-left: 0pt;
  padding-bottom: 11pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms .c0 {
  color: black;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 20px;

  font-style: normal;
}
.terms .c11 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.terms .c3 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;

  font-style: normal;
}
.terms .c14 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14.5pt;

  font-style: normal;
}
.terms .c10 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms .c26 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1761363636363635;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms .c18 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms .c12 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;

  font-style: normal;
}
.terms .c28 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.1500022727272727;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms .c4 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11.5pt;

  font-style: normal;
}
.terms .c24 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms .c17 {
  font-weight: 400;
  vertical-align: baseline;

  font-style: normal;
}
.terms .c19 {
  text-decoration: none;
  vertical-align: baseline;

  font-style: normal;
}
.terms .c9 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  color: #3030f1;
  text-decoration: underline;
}
.terms .c20 {
  color: #000000;
  font-size: 19.5pt;
}
.terms .c27 {
  max-width: 756pt;
  padding: 36pt 18pt 36pt 18pt;
}
.terms .c5 {
  color: inherit;
  text-decoration: inherit;
}
.terms .c21 {
  color: #000000;
  font-size: 13pt;
}
.terms .c25 {
  padding: 0;
  margin: 0;
}
.terms .c7 {
  background-color: #ffffff;
}
.terms .c16 {
  font-weight: 700;
}
.terms .c22 {
  font-size: 19.5pt;
}
.terms .c2 {
  font-size: 10.5pt;
}
.terms .c23 {
  color: #0a365a;
}
.terms .c13 {
  height: 11pt;
}
.terms .c6 {
  font-size: 11.5pt;
}
.terms .c1 {
  color: #595959;
}
.terms .c15 {
  margin-left: 15pt;
}
.terms .title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;

  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms .subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;

  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms li {
  color: #000000;
  font-size: 11pt;
}
.terms p {
  margin-bottom: 15px;
  color: #000000;
  font-size: 11pt;
}
.terms h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;

  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;

  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;

  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;

  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;

  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;

  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.terms .c0,
.terms .c6,
.terms .c15 {
  margin-left: 0;
}
.terms .c15 {
  padding: 0;
}
.terms .c10 {
  border: none !important;
}
.c7,
.terms .c15 {
  background: transparent;
}
.terms .li-bullet-0 {
  color: black;
  text-decoration: none;
  padding: 5px 0 5px 5px;
  margin-left: 19px;
}
.terms .lst-kix_esscojthnlic-1 {
  padding-left: 30px;
}
.terms .c13 {
  margin: 10px 0 6px;
}
.terms .c8 {
  margin: 10px 0 6px 0;
}
.terms .c16 {
  height: auto !important;
}
.terms .c9 {
  color: black;
  margin-bottom: 5px;
}
.terms .c23 {
  margin-top: 10px;
}

/* .terms ol {
  margin: 0;
  padding: 0;
}
.terms table td,
.terms table th {
  padding: 0;
}
.terms .c10 {
  border-right-style: solid;
  padding: 5pt 5pt 5pt 5pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 252pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.terms .c0 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.terms .c17 {
  -webkit-text-decoration-skip: none;
  color: #000000;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 11pt;
  font-style: italic;
}
.terms .c7 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: normal;
}
.terms .c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-style: normal;
}
.terms .c8 {
  -webkit-text-decoration-skip: none;
  color: #000000;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 11pt;
  font-style: normal;
}
.terms .c1 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms .c12 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;
  font-style: normal;
}
.terms .c19 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 16pt;
  font-style: normal;
}
.terms .c21 {
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}
.terms .c14 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.terms .c5 {
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.terms .c18 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
}
.terms .c15 {
  background-color: #ffffff;
  max-width: 756pt;
  padding: 36pt 18pt 36pt 18pt;
}
.terms .c3 {
  font-weight: 400;
}
.terms .c4 {
  padding: 0;
  margin: 0;
}
.terms .c6 {
  margin-left: 36pt;
  padding-left: 0pt;
}
.terms .c13 {
  color: inherit;
  text-decoration: inherit;
}
.terms .c11 {
  font-weight: 700;
}
.terms .c20 {
  font-size: 14pt;
}
.terms .c16 {
  height: 0pt;
}
.terms .c9 {
  height: 11pt;
}
.terms .title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms .subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms li {
  color: #000000;
  font-size: 11pt;
}
.terms p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
}
.terms h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.terms .li-bullet-0 {
  color: black;
  text-decoration: none;
  padding: 5px 0 5px 5px;
  margin-left: 19px;
}
.terms .lst-kix_esscojthnlic-1 {
  padding-left: 30px;
}
.terms .c13 {
  margin: 10px 0 6px;
}
.terms .c8 {
  margin: 10px 0 6px 0;
} */
