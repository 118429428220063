@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.apple-shadow {
  border-radius: 10px;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.1),
    0 3px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    top: 0;
    opacity: 0;
  }

  to {
    top: 75px;
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

.slide-down-effect {
  animation: slideDown 0.5s ease-out forwards;
}

.slide-up-effect {
  animation: slideUp 0.5s forwards;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #f5f5f7;
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

.pulsating-div {
  animation: pulsate 1s infinite;
  animation-delay: 200ms;
}

.scrollbar-custom {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

:root {
  /* Scale setup */
  --button-height: 37;
  /* in px */
  --height-to-scale: 43;
  /* in px */
  --scale-ratio: calc(var(--height-to-scale) / var(--button-height));

  --chakra-zIndices-modal: 9999 !important;

  /* Slide setup */
  --button-height-px: 37px;
  --button-vertical-padding-px: 10px;
  --button-content-spacing-px: calc(var(--button-height-px) + var(--button-vertical-padding-px) * 2);
  --slide-step-1: calc(var(--button-height-px) * -1);
  --slide-step-2: calc(var(--button-height-px) * -2);
}

.button {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-wrap: nowrap;
  height: var(--button-height-px);
  padding: var(--button-vertical-padding-px) 14px;
  border: 1px solid #120a31;
  border-radius: 80px;
  background-color: white;
  color: #120a31;
  font-size: 14px;
  font-weight: 800;
  line-height: 16px;
  cursor: pointer;
  transition: all 200ms;
}

.button:hover {
  background-color: #e8e4f2;
  color: #422d8f;
}

.button:focus {
  background-color: #e8e4f2;
  outline: none;
}

.button:active {
  background-color: #422d8f;
  color: #ffffff;
}

.disabled {
  border: 1px solid #999999;
  background-color: white;
  color: #999999;
}

.disabled:hover,
.disabled:focus,
.disabled:active {
  border: 1px solid #999999;
  background-color: white;
  color: #999999;
  cursor: default;
}

.copy {
  display: flex;
  align-items: center;
  justify-content: start;
}

.icon {
  margin-right: 6px;
}

/* Align content to animate */

.button {
  overflow: hidden;
}

.copied {
  visibility: hidden;
  margin-top: var(--button-content-spacing-px);
}

/* Animations */

.button.animate {
  background-color: #120a31;
  color: #ffffff;
  box-shadow: none;
  animation: scale 3s cubic-bezier(1, -0.5, 0, 1.5) forwards;
}

.animate .content {
  animation: slide 3s cubic-bezier(1, -0.5, 0, 1.5) forwards;
}

.animate .copied {
  visibility: visible;
  font-size: 16px;
  font-weight: 600;
}

@keyframes scale {
  0% {
    transform: none;
  }

  12.5% {
    transform: none;
  }

  25% {
    transform: scale(var(--scale-ratio));
  }

  37.5% {
    transform: scale(var(--scale-ratio));
  }

  50% {
    transform: none;
  }

  100% {
    transform: none;
  }
}

@keyframes slide {
  0% {
    transform: none;
  }

  12.5% {
    transform: translateY(var(--slide-step-1));
  }

  25% {
    transform: translateY(var(--slide-step-1));
  }

  37.5% {
    transform: translateY(var(--slide-step-2));
  }

  87.5% {
    transform: translateY(var(--slide-step-2));
  }

  100% {
    transform: none;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}